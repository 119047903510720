<template>
    <div class="dp-flex items-center">
      <i
        class=" rounded-full inline-block w-1 h-1 mr-2 align-middle"
        :class="{
          'bg-green-500': type == 'success',
          'bg-red-500': type == 'error',
        }"
        :style="doitStyle"
      ></i>
      <div
        class="align-middle text-sky-500 flex-1"
        :style="contentStyle"
      >
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: 'success',
      },
      doitColor: String,
      textColor: String,
    },
    computed: {
      doitStyle() {
        let style = {};
        if (this.doitColor) {
          style.color = this.doitColor;
        }
        return style;
      },
      contentStyle() {
        let style = {};
        if (this.textColor) {
          style.color = this.textColor;
        }
        return style;
      },
    },
  };
  </script>
  <style scoped>
  
  .items-center {
    align-items: center;
  }
  .rounded-full {
    border-radius: 50%;
  }
  .inline-block {
    display: inline-block;
  }
  .w-1 {
    width: 4px;
  }
  .h-1 {
    height: 4px;
  }
  .mr-2 {
    margin-right: 8px;
  }
  .align-middle {
    vertical-align: middle;
  }
  .bg-green-500 {
    background-color: rgb(34 197 94);
  }
  .bg-red-500 {
    background-color: rgb(239 68 68);
  }
  </style>